exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogpage-js": () => import("./../../../src/pages/blogpage.js" /* webpackChunkName: "component---src-pages-blogpage-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index-1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projectpage-js": () => import("./../../../src/pages/projectpage.js" /* webpackChunkName: "component---src-pages-projectpage-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/Seo.js" /* webpackChunkName: "component---src-pages-seo-js" */)
}

